export default [
  {
    title: 'Listening Tests',
    icon: 'RadioIcon',
    children: [
      {
        title: 'Miễn Phí',
        route: 'listening-list-free',
      },
      {
        title: 'Trả Phí',
        route: 'listening-list-premium',
      },
      {
        title: 'Thêm mới',
        route: { name: 'listening-add' },
      },
    ],
  },
]
