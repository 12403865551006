export default [
  {
    title: 'Reading Tests',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'Miễn Phí',
        route: 'reading-list-free',
      },
      {
        title: 'Trả Phí',
        route: 'reading-list-premium',
      },
      {
        title: 'Thêm mới',
        route: { name: 'reading-add' },
      },
    ],
  },
]
