export default [
  {
    title: 'Speaking Tests',
    icon: 'Volume2Icon',
    children: [
      {
        title: 'Miễn Phí',
        route: 'speaking-list-free',
      },
      {
        title: 'Trả Phí',
        route: 'speaking-list-premium',
      },
      {
        title: 'Thêm mới',
        route: { name: 'speaking-add' },
      },
    ],
  },
]
