export default [
  {
    title: 'Bài viết',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Danh sách',
        route: 'blog-list',
      },
      {
        title: 'Thêm mới',
        route: { name: 'blog-add' },
      },
    ],
  },
]
