export default [
  {
    title: 'Writing Tests',
    icon: 'PenToolIcon',
    children: [
      {
        title: 'Miễn Phí',
        route: 'writing-list-free',
      },
      {
        title: 'Trả Phí',
        route: 'writing-list-premium',
      },
      {
        title: 'Thêm mới',
        route: { name: 'writing-add' },
      },
    ],
  },
]
