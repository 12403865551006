export default [
  {
    title: 'Tài khoản',
    icon: 'UserIcon',
    children: [
      {
        title: 'Danh sách',
        route: 'users-list',
      },
      {
        title: 'Chi tiết',
        route: { name: 'users-view', params: { id: 1 } },
      },
      {
        title: 'Chỉnh sửa',
        route: { name: 'users-edit', params: { id: 1 } },
      },
    ],
  },
]
