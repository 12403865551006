// import others from './others'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'
import users from './users'
import posts from './posts'
import reading from './reading'
import writing from './writing'
import listening from './listening'
import speaking from './speaking'
import file from './file'

// Array of sections
export default [...users, ...posts, ...reading, ...writing, ...listening, ...speaking, ...file]
